<template>
  <vz-dialog
    ref="dialog"
    name="region"
    title="Выберите ваш город"
    full-screen
    public
    hide-footer
    class="vz-region-modal text-body"
    @showed="onShowed"
  >
    <city-autocomplete
      v-model="city"
      class="vz-region-modal-control"
      label=""
      @change="onChangeCity"
    />
    <div v-if="selectedCity" class="vz-region-modal-selected-city text-subtitle">
      <span>Ваш филиал - </span>
      <nuxt-link
        class="color-primary vz-cursor-pointer"
        :to="getLink(filial)"
        @click="onClick(filial)"
      >
        {{ filialName }}
      </nuxt-link>
    </div>
    <region-cities @change="$hideDialog({ name: 'region' })" />
  </vz-dialog>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia';
import {useCityStore} from '~/stores/city';
import {Location} from '~/types/types';
import {deepCopyObject, getFullLinkRedirect} from '~/common/functions';
import CityAutocomplete from '~/components/autocomplete/city-autocomplete.vue';
import RegionCities from '~~/components/public/region-cities/region-cities.vue'
import VzDialog from '~/components/ui-kit/vz-dialog.vue';

const { currentCity, currentCityAbbr, rclCityList } = storeToRefs(useCityStore())
const { $hideDialog } = useNuxtApp()

const city = ref('')
const locations = ref<Location[]>([])
const selectedCity = ref<Location>()

const filial = computed(() => {
  if (selectedCity.value?.name) {
    let filial = locations.value.filter(x => x.guid === selectedCity.value?.rcl_guid)[0]
    return filial || selectedCity.value
  }
  return null
})

const filialName = computed((): String => {
  return filial.value?.name || ''
})

function onShowed() {
  city.value = currentCity.value.guid
  locations.value = deepCopyObject(rclCityList.value)
}

function onChangeCity(value: string, item: Location): void {
  if (item && !item.rcl_guid) {
    // if (item.country !== 'KZ') {
    //   currentCityAbbr.value = null
    //   console.log('clear currentCityAbbr')
    // } else if (currentCity.value.country === 'KZ') {
    //   currentCityAbbr.value = item.abbr || item.translit
    // }
    onClick(item, true)
    return
  }
  city.value = value
  selectedCity.value = item
}

function getLink(location: Location): string {
  if (location.country !== currentCity.value.country) {
    return getFullLinkRedirect(location, useRoute().fullPath)
  }
  return ''
}

function onClick(location: Location, force = false): void {
  if (location.country !== currentCity.value.country) {
    navigateTo(getLink(location), { external: true })
    return
  }
  if (location.country === currentCity.value.country || force) {
    currentCityAbbr.value = location.abbr || location.translit
    currentCity.value = location
  }
  $hideDialog({ name: 'region' })
  // currentCityAbbr.value = city.abbr || city.translit
}
</script>
